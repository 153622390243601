import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import axios from "axios";
import "../UserDetailsForm/VerifyForm.css";
import { decrypter, encrypter } from "../EnquiryForm/EnquiryForm";

const OtpVerifyCorporate = () => {
    const toastId = useRef();
    const { personId } = useParams();
    const boidRef = decrypter(useParams().boidRef);

    const gender = useParams().gender;
    const mobile = decrypter(useParams().mobile);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [OTP, setOTP] = useState("");

    const handleOtpSubmit = async () => {
        console.log("caled", OTP.length);
        if (OTP.length == 4) {
            // try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/user/verify-request-status`,
                {
                    id: personId,
                    otp: OTP,
                }
            );

            console.log(response);

            if (
                (response && response.statusCode === 200) ||
                response.data.statusCode === 200
            ) {
                navigate(`/success/${gender}/${encrypter(boidRef)}/${encrypter(mobile)}`);
            } else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Invalid OTP");
                }
            }
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.warning("Enter valid OTP");
            }
        }
    };

    return (
        <>
            <main className="verifyotp-main">
                <section className="forgot-card-container">
                    <p className="forgot__title">Verify your OTP</p>
                    <p className="forgot__entermail">OTP is sent to your mobile number.</p>
                    <form
                        className="forgot__form-container"
                        onSubmit={handleSubmit(handleOtpSubmit)}
                    >
                        <article>
                            <div className="input-control">
                                <OTPInput
                                    value={OTP}
                                    onChange={setOTP}
                                    autoFocus
                                    OTPLength={4}
                                    otpType="number"
                                    disabled={false}
                                    inputStyles={{
                                        fontSize: "24px",
                                        width: "36px",
                                        height: "36px",
                                        margin: "4px",
                                        borderTop: "0px",
                                        borderLeft: "0px",
                                        borderRight: "0px",
                                        outline: "none",
                                        borderColor: "#ed1e79",
                                        backgroundColor: "#fff",
                                    }}
                                />
                            </div>
                            {/* <ResendOTP
                className="resend-button"
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={handleResendOtp}
                style={{
                  border: "0",
                  textAlign: "end",
                  color: "#a7aaab",
                }}
              /> */}
                        </article>
                        <div className="verify-form__button">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    );
};

export default OtpVerifyCorporate;
