import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import { ToastContainer } from "react-toastify";
import Registered from "../Pages/Registered/Registered";
// import Login from "../Pages/Login/Login";

export default function PrivateRoutes() {
  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={800} position="top-center" />
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} exact />
        <Route path="/login" element={<Navigate replace to="/home" />} exact />
        <Route path="/home" element={<Home />} exact />
        <Route path="/userdata/:corpRef" exact element={<Registered />} />
      </Routes>
    </>
  );
}
