import React, { useContext, useState, useEffect } from "react";
import "./home.css";
// import background from "../../Assets/team.svg";
import logo from "../../Assets/AImagelogo2.png";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { saveAs } from "file-saver";
import logo1 from "../../Assets/logo.png";
// import { encrypter } from "../../components/EnquiryForm/EnquiryForm";

export default function Home() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(AuthContext);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "30px",
      width: "300px",
    },
  };

  useEffect(() => {
    if (!state?.userid) {
      navigate("/login");
    }
  }, [state, dispatch]);

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  // const handleData = () =>{
  //   navigate(`/userdata/${state.uniquelink}`);
  // }

  const downloadQr = () => {
    saveAs(state?.qrcode, "image jpg");
  };
  return (
    <>
      <header>
        <div className="wrapper">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <ul className="nav-area">
            {/* <li>
              <button className="nav_btn" onClick={(e) => toggleModal(e)}>Show QR</button>
            </li> */}
            {/* <li>
              <button className="nav_btn" onClick={handleData}>
                user Details
              </button>
            </li> */}
            <li>
              <button className="nav_btn" onClick={handleLogout}>
                Logout
              </button>
            </li>

          </ul>
        </div>
        <div className="welcome-text">
          <h2>
            We are <span>Creative</span>
          </h2>
          <br />
          <button className="modal_btn" onClick={openModal}>
            Show QR
          </button>
        </div>
      </header>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="My dialog"
      >
        <div className="modal_container">
          <div className="modal_wrapper">
            <div className="modal_header">
            <img src={logo1} style={{width: "100px" }} />
              {/* <h2>
                AI <span>mage</span>
              </h2> */}
              <p>Your QR Code is Ready!</p>
            </div>
            <div class="modal_banner-image">
              <img
                src={state?.qrcode}
                alt={state?.companyname}
                className="qr-image"
              />
            </div>
            <div className="modal_name">
              <p>"{state?.companyname}"</p>
            </div>
          </div>
          <div className="modal_button-wrapper">
            <button className="modal_btn" onClick={downloadQr}>
              Download
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
