import React from "react";
import "./Thankyou.css";

const ThankYou = () => {
  return (
    <>
      <div class="content">
        <div class="wrapper-1">
          <div class="wrapper-2">
            <h1>Thank you !</h1>
            <p>Thanks for submitting the request. </p>
            <p>your photos will reach you as soon as possible. </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
