import { createContext } from "react";
export const AuthContext = createContext({});

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        userid: action.payload.userid, 
        qrcode: action.payload.qrcode,
        uniquelink: action.payload.uniquelink,
        companyname: action.payload.companyname,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        userid: "",
        qrcode: "",
        uniquelink: "",
        companyname: ""
      };

    default:
      return state;
  }
};
