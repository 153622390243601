import styled from '@emotion/styled';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    color: 'white',
    backgroundColor: 'black'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function Registered() {

  const { corpRef } = useParams();
  const [details, setDetails] = useState();

  const toastId = useRef(null);

  const data = useCallback(async () => {
    let userDetails = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/studio-records`, { refId: corpRef }
    );
    if (userDetails && userDetails.data.result) {
      setDetails(userDetails.data.result);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("success");
      }

    }
  }, [corpRef]);

  console.log(details);

  useLayoutEffect(() => {
    data();
  }, [corpRef]);


  return (
    <div>
      {/* <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>mobile</th>
            <th>gender</th>
          </tr>
        </thead>
        <tbody>
          {details?.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td>{data.mobile}</td>
                <td>{data.gender}</td>
              </tr>
            );
          })}
        </tbody>
      </table> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>S.NO</StyledTableCell>
              <StyledTableCell align='center'>B0Id</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Mobile</StyledTableCell>
              <StyledTableCell align="center">gender</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{row.Emp_ID}</StyledTableCell>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.mobile}</StyledTableCell>
                <StyledTableCell align="center">{row.gender}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
