import React, { useRef, useState } from "react";
import "./signup.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const navigate = useNavigate();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealcpwd, setIsRevealCPwd] = useState(false);
  const toastId = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const handledata = async (data) => {
    const {
      companyname,
      email,
      mobile,
      address,
      password,
    } = data;
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/signup`, {
        companyname: companyname.trim(),
        email: email.toLowerCase().trim(),
        mobile,
        address,
        password,
      })
      .then((res) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(res?.data?.message);
        }
        // console.log(res);
        // navigate('/login')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div class="signup_container">
        <div class="signup_title">Registration</div>
        <div class="signup_content">
          <form onSubmit={handleSubmit(handledata)}>
            <div class="user-details">
              <div class="input-box">
                <span class="details">Company Name</span>
                <input
                  type="text"
                  placeholder="Enter your name"
                  {...register("companyname", {
                    required: {
                      value: true,
                      message: "Your companyName is required",
                    },
                  })}
                />
                {errors.companyname && <p>{errors.companyname.message}</p>}
              </div>
              {/* <div class="input-box">
                <span class="details">location</span>
                <input
                  type="text"
                  placeholder="location"
                  {...register("location", {
                    required: {
                      value: true,
                      message: "Your location is required",
                    },
                  })}
                />
                {errors.location && <p>{errors.location.message}</p>}
              </div>
              <div class="input-box">
                <span class="details">Username</span>
                <input
                  type="text"
                  placeholder="Enter your username"
                  {...register("Username", {
                    required: {
                      value: true,
                      message: "Username is required",
                    },
                  })}
                />
                {errors.Username && <p>{errors.Username.message}</p>}
              </div> */}
              <div class="input-box">
                <span class="details">Email</span>
                <input
                  type="email"
                  placeholder="Enter your email"
                  {...register("email", {
                    required: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'invalid email address',
                    },
                  })}
                />
                {errors.email && <p style={{ color: "#ff0000" }}>{errors.email.message}</p>}
              </div>
              <div class="input-box">
                <span class="details">Phone Number</span>
                <input
                  type="text"
                  placeholder="Enter your Mobile number"
                  minLength={10}
                  {...register("mobile", {
                    required: {
                      value: true,
                      message: "mobile is required",
                    },
                  })}
                />
                {errors.mobile && <p style={{ color: "#ff0000" }}>{errors.mobile.message}</p>}
              </div>
              <div class="input-box">
                <span class="details">Address</span>
                <textarea
                  type="text"
                  placeholder="Enter your Address"
                  {...register("address", {
                    required: {
                      value: true,
                      message: "Address is required",
                    },
                  })}
                />
                {errors.Address && <p style={{ color: "#ff0000" }}>{errors.Address.message}</p>}
              </div>
              <div class="input-box">
                <span class="details">Password</span>
                <input
                  type={isRevealPwd ? "text" : "password"}
                  placeholder="Enter your password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z( +\w+)])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/g,
                      message:
                        "Use 8 or more characters with a mix of letters,numbers,space & symbols",
                    },
                  })}
                />
                <div
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  style={{
                    position: "absolute",
                    right: "30px",
                    bottom: "12px",
                  }}
                >
                  {isRevealPwd ? (
                    <i
                      className="fa-regular fa-eye"
                      style={{ color: "#a7aaab" }}
                    ></i>
                  ) : (
                    <i
                      className="fa-regular fa-eye-slash"
                      style={{ color: "#a7aaab" }}
                    ></i>
                  )}
                </div>
                {errors.password &&
                  errors.password.type &&
                  errors.password.type === "required" && (
                    <p style={{ color: "#ff0000" }}>{errors.password.message}</p>
                  )}
                {errors?.password?.type === "pattern" && (
                  <p style={{ color: "#ff0000" }}>{errors.password.message}</p>
                )}
              </div>
              <div class="input-box">
                <span class="details">Confirm Password</span>
                <input
                  type={isRevealcpwd ? "text" : "password"}
                  placeholder="Confirm your password"
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                    required: {
                      value: true,
                      message: "Confirm Password is required",
                    },
                  })}
                />
                <div
                  onClick={() => setIsRevealCPwd((prevState) => !prevState)}
                  style={{
                    position: "absolute",
                    right: "30px",
                    bottom: "12px",
                  }}
                >
                  {isRevealcpwd ? (
                    <i
                      className="fa-regular fa-eye"
                      style={{ color: "#a7aaab" }}
                    ></i>
                  ) : (
                    <i
                      className="fa-regular fa-eye-slash"
                      style={{ color: "#a7aaab" }}
                    ></i>
                  )}
                </div>
                {errors.confirmPassword &&
                  errors.confirmPassword.type &&
                  errors.confirmPassword.type === "required" && (
                    <p style={{ color: "#ff0000" }}>
                      {errors.confirmPassword.message}
                    </p>
                  )}

                {errors?.confirmPassword?.type === "validate" && (
                  <p style={{ color: "#ff0000" }}>{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>

            <div class="button">
              <input type="submit" value="Register" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
