import React from "react";
import { Route, Routes } from "react-router-dom";
import SignUp from "../Pages/Signup/SignUp";
import UserDetailsForm from "../components/UserDetailsForm/UserDetailsForm";
import EmailForm from "../components/EmailForm/EmailForm";
import { ToastContainer } from "react-toastify";
import VerifyForm from "../components/UserDetailsForm/VerifyForm";
import CorporateForm from "../components/CorporateForm/CorporateForm";
import ThankYouInfo from "../components/UserDetailsForm/ThankYouInfo";
import OtpVerifyCorporate from "../components/CorporateForm/OtpverifyCorporate";
import ThankYou from "../components/UserDetailsForm/ThankYou";

const Login = React.lazy(() => import("../Pages/Login/Login"));

export default function PublicRoutes() {

  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={800} position="top-center" />

      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/krato-personal-signup" exact element={<SignUp />} />
        <Route path="/qr/:studioRef" exact element={<EmailForm />} />
        <Route path="/scan/:studioRef" exact element={<UserDetailsForm />} />
        <Route path="/c-scan/:corpRef" exact element={<CorporateForm />} />
        <Route path="/signup" exact element={<Login />} />
        <Route path="/otp-verify/:personId" exact element={<VerifyForm />} />
        <Route path="/thankyou" exact element={<ThankYou />} />
        <Route path="/success/:gender/:boidRef/:mobile" exact element={<ThankYouInfo />} />
        <Route path="/otp-check/:personId/:gender/:boidRef/:mobile" exact element={<OtpVerifyCorporate />} />
        <Route path="/success" exact element={<ThankYouInfo />} />

      </Routes>
    </>
  );
}
