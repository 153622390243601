import React, { useEffect } from "react";
import PublicRoutes from "./routes/PublicRoutes";
import { useReducer } from "react";
import { AuthContext, reducer } from "./context";
import PrivateRoutes from "./routes/PrivateRoutes";
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  userid: "",
  qrcode: "",
  uniquelink: "",
  companyname: "",
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user")) || state;

    if (userData?.userid) {
      dispatch({
        type: "LOGIN",
        payload: {
          ...userData,
        },
      });
    }
  }, []);

  return (
    <>
      <React.Suspense>
      <AuthContext.Provider value={{ state, dispatch }}>
        <div className="App">
          {state.userid ? <PrivateRoutes /> : <PublicRoutes />}
        </div>
      </AuthContext.Provider>
      </React.Suspense>
    </>
  );
}

export default App;
