import axios from "axios";

const uploadFileToAWS = async (
  folderName,
  file,
  fileName,
  contentType,
  setLoading
) => {
  const filetoupload = file;
  const fileMeta = {
    folderName: folderName,
    fieldname: fileName,
    fileMime: contentType,
  };
  setLoading((prev) => prev + 20);

  const signedUrl = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/user/upload-selfie`,
    fileMeta
  );

  if (signedUrl) {
    setLoading((prev) => prev + 20);
    const uploaded = await axios.put(signedUrl?.data?.s3PreUrl, filetoupload);
    console.log(uploaded);
    setLoading((prev) => prev + 10);
    return uploaded;
  }
};

export default uploadFileToAWS;
