import React, { useRef, useState } from "react";
import "./enquiry.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';


export const encrypter = (data) => {
  const encoded = AES.encrypt(data, process.env.REACT_APP_CRYPTO_SECRET);
  return encodeURIComponent(encoded.toString());
};

export const decrypter = (data) => {
  let actual = decodeURIComponent(data);
  const decoded = AES.decrypt(actual, process.env.REACT_APP_CRYPTO_SECRET);
  const decrypted = enc.Utf8.stringify(decoded);
  return decrypted;
};

export default function EnquiryForm() {
  const [open, setOpen] = useState(false);
  const toastId = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handledata = async (data) => {
    const { name, mobile } = data;
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/signup`, {
        name,
        mobile,
      })
      .then((res) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <div class="text-container">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium
          placeat quo aut repudiandae, explicabo sequi quis cum provident quam
          commodi quisquam culpa, recusandae, quibusdam blanditiis!
        </p>
      </div> */}
      <div class="create_New-header">
        <button onClick={() => setOpen(!open)}>Enquire Now</button>
      </div>

      {open && (
        <>
          <div class="create_New">
            <form
              className="create_New-form"
              onSubmit={handleSubmit(handledata)}
            >
              <label htmlFor="mobile" className="create_New-label">
                Name
              </label>
              <input
                className="create_New-input"
                type="text"
                placeholder="name"
                id="name"
                {...register("name", {
                  required: {
                    value: true,
                    message: "name is required",
                  },
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
              <label className="create_New-label" htmlFor="password">
                Contact Number
              </label>
              <input
                className="create_New-input"
                type="tel"
                placeholder="Mobile Number"
                id="mobile"
                {...register("mobile", {
                  required: {
                    value: true,
                    message: "Mobile Number is required",
                  },
                })}
              />
              {errors.mobile && <p>{errors.mobile.message}</p>}

              <button className="create_New-button">Submit</button>
            </form>
          </div>
        </>
      )}
    </>
  );
}
