import React, { useRef, useState, useLayoutEffect } from "react";
import "./corporateform.css";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import uploadFileToAWS from "../../helpers/uploader";
import axios from "axios";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import ReactModal from "react-modal";
import Camera from "../../Assets/camera.svg";

import { Typography, Box, CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import imageCompression from "browser-image-compression";
// import request from "request";
import { useNavigate } from "react-router-dom";
import { details } from "./datails";
import { encrypter } from "../EnquiryForm/EnquiryForm";
import logoairtel from "../../Assets/airtelpng.png";

export default function CorporateForm() {
  const { corpRef } = useParams();
  const navigate = useNavigate();
  const toastId = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const [valid, setValid] = useState(null);
  const [folderName] = useState(format(new Date(), "dd-MM-yyyy"));
  const [selfie, setSelfie] = useState("");
  const [selfieError, setSelfieError] = useState(null);
  const [loading, setLoading] = useState(0);
  const [value, setValue] = useState();
  const [mobileError, setMobileError] = useState(false);
  const [gender, setGender] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "30px",
    },
  };

  const getRefData = async () => {
    let userData = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/user/${corpRef}`
    );
    if (userData && userData?.data?.email) {
      setValid(true);
    } else {
      // await runOtp();
      setValid(false);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warning("Invalid Url");
      }
    }
  };

  useLayoutEffect(() => {
    getRefData();
    //eslint-disable-next-line
  }, [corpRef]);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const mimeTypes = ["image/tiff", "image/png", "image/jpg", "image/jpeg"];

  const onSubmitPhoto = async (e) => {
    setLoading(0);
    e.target.files[0] && setLoading((prev) => prev + 10);
    const filePD = e.target.files[0];

    try {
      const options = {
        maxSizeMB: 1.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(filePD, options);

      const contentType = mimeTypes.includes(compressedFile?.type)
        ? compressedFile?.type
        : null;
      if (!compressedFile || !contentType) {
        return;
      }
      setLoading((prev) => prev + 20);
      const form = new FormData();
      form.append("file", compressedFile);
      const nameArr = compressedFile.name.split(".");
      const filePDName =
        Date.now() +
        "-" +
        nameArr[0].replace(/[^0-9a-zA-Z.]/g, "_") +
        "." +
        nameArr[1];
      const saved = await uploadFileToAWS(
        folderName,
        compressedFile,
        filePDName,
        contentType,
        setLoading
      );
      if (saved?.status === 200) {
        let fileUrl = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${folderName}/${filePDName}`;
        setSelfie(fileUrl);
        setSelfieError(false);
        setLoading((prev) => prev + 20);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (data) => {
    setLoading(0);
    const { name, BoId, gender } = data;

    console.log(data)

    const result = details.filter((det) => { return det.boid == BoId })

    if (!selfie) {
      const addToSecret = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/no-selfie`,
        {
          name,
          mobile: value,
          referenceid: corpRef,
          gender,
        }
      );
      setSelfieError(true);
    } else {
      if (!value || !isValidPhoneNumber(value.toString())) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.warning("WhatsApp Number is Invalid");
        }
        setMobileError(true);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/user/photo-req-gender`,
          {
            name,
            mobile: value,
            selfie,
            referenceid: corpRef,
            gender,
          }
        );
        console.log(response);
        if (response && response.data.status === "sms") {
          setValue();
          setSelfie("");
          setMobileError(false);

          // setTimeout(() => {
          //   window.close();
          // }, 2000);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(response.message);
          }
          const genRoute = gender === 'male' ? 'm' : "f"          
          // navigate(`/success/${genRoute}/${encrypter(BoId)}/${encrypter(value)}`);

          // setGender("");
          reset({ name: "", gender: "" });
        // } else if (response.data.status === "sms") {
          navigate(`/otp-check/${response?.data?.insertId}/${genRoute}/${encrypter(BoId)}/${encrypter(value)}`);
        }
      }
    }
  };

  return (
    <>
      <section>
        <div className="wrapper">
          <ReactModal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="My dialog"
          >
            {/* {valid ? ( */}
            <div className="brand-logo">
              <img src={logoairtel} alt="airtel" width={150} height={100} />
            </div>
            <div className="form-container">
              {/* <h1>AImage</h1> */}
              <h3>
                <span> Let's Get Your Image</span>
              </h3>
              <label className="userdetail-label">Upload your Selfie</label>
              <div className="selfie-container">
                {loading !== 0 && loading < 100 ? (
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress variant="determinate" value={loading} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                      >
                        {`${Math.round(loading)}%`}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <label
                    className="selfie-btn"
                    htmlFor="selfie"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={Camera} alt="camera" className="camera_img" />
                    Take a Selfie *
                  </label>
                )}
                <input
                  required
                  className="userdetail-file-input"
                  type="file"
                  accept="image/*"
                  capture="user"
                  id="selfie"
                  onChange={onSubmitPhoto}
                  style={{ display: "none" }}
                />
                {selfieError && !selfie && !loading && (
                  <p style={{ color: "#ff0000" }}>Please Upload Selfie here</p>
                )}

                {selfie && (
                  <p
                    style={{
                      color: "#00BA61",
                      marginLeft: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Uploaded
                  </p>
                )}
              </div>

              <form
                className="userdetail-form"
                onSubmit={handleSubmit(submitData)}
              >
                <label htmlFor="name" className="userdetail-label">
                  Name *
                </label>
                <input
                  className="userdetail-input"
                  type="text"
                  placeholder="Name"
                  id="name"
                  {...register("name", {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                    },
                  })}
                />
                <p style={{ color: "#ff0000" }}>
                  {errors.name && "Please enter valid name"}
                </p>
                <>
                  <label className="userdetail-label" htmlFor="gender">
                    Gender*
                  </label>
                  <div className="userdetail-gender-input">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      onChange={(e) => setGender(e.target.value)}
                      {...register("gender", { required: true })}
                    />
                    Male
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      onChange={(e) => setGender(e.target.value)}
                      {...register("gender", { required: true })}
                    />
                    Female
                  </div>
                  <p style={{ color: "#ff0000" }}>
                    {errors.gender && "Please select gender"}
                  </p>
                </>
                <label htmlFor="boid" className="userdetail-label">
                  Employee Id
                </label>
                <input
                  className="userdetail-input"
                  type="text"
                  placeholder="Eg: B0123456"
                  id="BoId"
                
                  {...register("BoId", {
                    required: true,
                    validate: value => {
                      let dd = details.filter(d => d.boid === value)
                      return dd.length > 0 ? true : false
                    }
                  })}
                />

                <p style={{ color: "#ff0000" }}>
                  {errors.BoId?.type === 'required' ? "Please enter employeeId" : errors.BoId?.type === 'validate' && "Invalid Employeeid"}
                </p>
                <>
                  <label className="userdetail-label" htmlFor="mobile">
                    WhatsApp Number *
                  </label>
                  <PhoneInput
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    className="userdetail-input"
                    value={value}
                    onChange={setValue}
                  />
                  <p style={{ color: "#ff0000" }}>
                    {value &&
                      !isValidPhoneNumber(value.toString()) &&
                      "Please enter valid Mobile Number"}
                  </p>
                </>
                <p style={{color:'#727272', fontSize: '10px'}}> All event photos that you are part of will be shared to this Whats App number</p>
                <button type="submit" className="userdetail-button">
                  Submit
                </button>
              </form>
            </div>
            {/* ) : (
              <>
                <p className="text-color">Loading ... </p>
              </>
            )} */}
          </ReactModal>
        </div>
      </section>
    </>
  );
}
