import React from "react";
import "./ThankyouInfo.css";
import { useParams } from "react-router";
import { details } from "../CorporateForm/datails";
import { decrypter } from "../EnquiryForm/EnquiryForm";
import { useEffect } from "react";
import axios from "axios";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

import pdf from '../../Assets/TN-ECF23.pdf';

const ThankYouInfo = () => {
  const boidRef = decrypter(useParams().boidRef);
  const gender = useParams().gender;
  const mobile = decrypter(useParams().mobile);

  const result = details.filter((det) => {
    return det.boid === boidRef;
  });

  // const sms = async () => {
  //   let content = result[0].roomNo ? `Hi ${result[0].name}, Your seat No: ${result[0].seating}. You will be staying in ${result[0].hotelName} Hotel, room no ${result[0].roomNo} ` : `Hi ${result[0].name}, Your seat No: ${result[0].seating}., your cab assembly point is ${result[0].pickupPoint}. ${result[0].pickupMsg} ${gender === 'f' && 'WomenHelp Line Ms. Neelima / Mobile: 9840516642'}`
  //   const respons = await axios.get(
  //     `https://api.authkey.io/request?authkey=faa1b84cc92829dd&mobile=${mobile.substring(3, 13)}&
  //     country_code=91&sid=8843&name=AImage&sms=${content}&pe_id=1001001001001001001991&template_id=TEST_1`
  //   );
  //   return respons
  // }

  const pdfDownload = () => {
    download(pdf);
  }


  const image = () => {
    var node = document.getElementById('content')
    htmlToImage.toJpeg(node)
      .then(function (dataUrl) {
        download(dataUrl, 'my-node.png');
        // var img = new Image();
        // img.src = dataUrl;
        // window.open(img);
        // document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });


  }

  useEffect(() => {
    setTimeout(() => {
      pdfDownload()
    }, 3000);
    image()
  }, [result, boidRef])
  return (
    <>

      <div id="content" class="content" >
        <div class="wrapper-1-info">
          <div class="wrapper-2-info">
            <h1>Thank you !</h1>
            <p>Thanks for submitting the request. </p>
            <p>your photos will reach you as soon as possible. </p>
          </div>

          <hr />
          <div className="wrapper-3-info">
            <h3 style={{ color: '#302e2e' }}>Event Details</h3>
            <div className="wrapper-3-info-p">
              <p>Seating Location: <b style={{ color: '#ed1e79' }}>{result[0].seating}</b></p>
              <hr />
              <br />
              {result[0].roomNo ? (
                <>
                  <h3 style={{ color: '#302e2e' }}>You are accomodating in,</h3>
                  <p >Hotel Name:
                  <b style={{ color: '#ed1e79' }}>{result[0].hotelName}</b>
                  </p>
                  <p>Room No: 
                  <b style={{ color: '#ed1e79' }}>{result[0].roomNo}</b>
                  </p>
                  <p>Checkout Time: 
                  <b style={{ color: '#ed1e79' }}>{result[0].Checkout_Time}</b>
                  </p>
                  <p>Drop Location:
                  <b style={{ color: '#ed1e79' }}>{result[0].Drop_points}</b>
                  </p>
                  <h3 style={{ color: '#302e2e' }}>Travel Spoc details</h3>
                  <p>Name :
                    <b style={{ color: '#ed1e79' }}>{result[0].DName}</b>
                  </p>
                  <p>Contact No :
                    <b style={{ color: '#ed1e79' }}>{result[0].DNumber}</b>

                  </p>
                </>
              ) : (
                <>
                  <h3 style={{ color: '#302e2e' }}>Travel Spoc details</h3>
                  <p>You cab pick-up point is “Parking Area”, Please assemble by 9pm and driver details will be sent via SMS 4hrs before travel time.</p>
                  <p>Name: 
                  <b style={{ color: '#ed1e79' }}>{result[0].DName}</b>
                  </p>
                  <p>Contact No: 
                  <b style={{ color: '#ed1e79' }}>{result[0].DNumber}</b>
                  </p>
                </>
              )}
              {gender === "f" && (
                <>
                  <p>Women Helpline:<b style={{ color: '#ed1e79' }}>9840516642</b> </p>
                </>
              )}
            </div>
          </div>
          <p style={{ color: 'red', fontSize: '12px' }}>This information has been stored in photo gallery</p>
          <hr />
          <p style={{ color: '#aaa' }}>Powered By: AImage</p>
          <p style={{ color: '#aaa' }}>Inquire @ 9566513987</p>

        </div>

      </div> 

{/* <div id="content" class="content" >
        <div class="wrapper-1-info">
          <div class="wrapper-2-info">
            <h1>Thank you !</h1>
            <p>Thanks for submitting the request. </p>
            <p>your photos will reach you as soon as possible. </p>
          </div>

          <hr />
          <div className="wrapper-3-info">
            <h3 style={{ color: '#302e2e' }}>Event Details</h3>
            <div className="wrapper-3-info-p">
              <p>Seating Location: <b style={{ color: '#ed1e79' }}> Zone -1, Row 12 to 16</b></p>
              <hr />
              <br />
            
                <>
                  <h3 style={{ color: '#302e2e' }}>You are accomodating in,</h3>
                  <p >Hotel Name:
                  <b style={{ color: '#ed1e79' }}>Coral Beach Resorts, Mahabalipuram</b>

                  </p>
                  <p>Room No: 
                  <b style={{ color: '#ed1e79' }}>307</b>
                  </p>
                  <p>Checkout Time: 
                  <b style={{ color: '#ed1e79' }}>10Am</b>

                  </p>
                  <p>Drop_Location: 
                  <b style={{ color: '#ed1e79' }}>Chennai Central Railway Station</b>

                  </p>
                  <h3 style={{ color: '#302e2e' }}>Travel Spoc details</h3>
                  <p>Name :
                    <b style={{ color: '#ed1e79' }}>Prem</b>
                  </p>
                  <p>Contact No :
                    <b style={{ color: '#ed1e79' }}>8754499704</b>

                  </p>
                </>
        
                <>
                  <h3 style={{ color: '#302e2e' }}>Travel Spoc details</h3>
                  <p>Please assemble by 9pm and your driver details will be sent to you 4hours before the travel time.</p>
                  <p>Name: 
                  <b style={{ color: '#ed1e79' }}>Prem</b>

                  </p>
                  <p>Contact No: 

                  </p>
                </>
          
            
                <>
                  <p>Women Helpline:<b style={{ color: '#ed1e79' }}>9840516642</b> </p>
                </>
      
            </div>
          </div>
          <p style={{ color: 'red', fontSize: '12px' }}>This information has been stored in photo gallery</p>
          <hr />
          <p style={{ color: '#aaa' }}>Powered By: AImage</p>
          <p style={{ color: '#aaa' }}>Inquire @ 9566513987</p>

        </div>

      </div> */}

    </>
  );
};

export default ThankYouInfo;
